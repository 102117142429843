
@import '../sites/semantic.css';
@import '../brands/theme-koodo-prepaid.css';
@import '../brands/theme-public-mobile.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply font-body;
    @apply text-base;
    @apply text-copy;
    @apply leading-tight;
}

h1 {
    @apply text-2xl leading-none text-h1 font-h1;
}

h2 {
    @apply text-xl leading-tight text-h2 font-h2;
}

h3 {
    @apply text-lg leading-none tracking-wide text-h3 font-h3;
}

h4 {
    @apply text-base leading-tight text-h4 font-h4;
}

/* Form elements */
input[type='email'],
input[type='sim'],
input[type='number'],
input[type='url'],
input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='time'],
input[type='search'],
input[type='tel'],
input[type='checkbox'],
select,
select[multiple],
textarea {
    @apply w-full px-4 text-sm placeholder-opacity-100 transition duration-150 ease-in-out border rounded outline-none border-primary h-11 text-copy placeholder-copy focus:border-primary focus:shadow focus:shadow-active focus:ring-0;
}

input[type='password'],
input[type='text'] {
    @apply w-full text-sm placeholder-opacity-100 transition duration-150 ease-in-out border rounded outline-none border-primary h-11 text-copy placeholder-copy focus:border-primary focus:shadow focus:shadow-active focus:ring-0;
}

input[type='radio']:focus, input[type='radio']:checked, input[type='radio']:checked:focus, input[type='radio']:checked:hover {
    background-image: none;
    @apply border-2 border-white border-solid ring-0 ring-offset-0;
}

input[type='radio'] {
    color: transparent;
    @apply bg-transparent;

}

input[type='search']::-webkit-calendar-picker-indicator {
    display: none;
}
[list]::-webkit-calendar-picker-indicator , [list]::-webkit-calendar-picker-indicator:hover{
    display: none !important;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

input[type="search"] {
    -moz-appearance: none !important;
    -webkit-appearance: textfield !important;
}

label {
    @apply leading-normal;
}

.empty::after {
    content: ' ';
    position: absolute;
}

.alignRightPseudo::after{
    right: 5%;
}
.alignLeftPseudo::after{
    left: 5%;
}
.alignBottomRightPseudo{
    top: -100px;
    right: -20px;
}

.alignBottomRightPseudo::after{
    right: 5%;
    top: 87%;
}


.alignBottomLeftPseudo::after{
    left: 5%;
    top: 87%;
}

input[type='checkbox']:checked, input[type='checkbox']:checked:hover, input[type='checkbox']:checked:focus {
    @apply  p-0 border-2 border-solid bg-primary border-primary ring-0 ring-offset-0;
}

input[type='checkbox'], input[type='checkbox']:focus {
    @apply p-0 bg-transparent border-2 border-solid ring-0 border-primary;
}

.plan-selection-three-g:checked, .plan-selection-three-g:hover, .plan-selection-three-g:focus, .plan-selection-three-g, .plan-card-hr-three-g {
    border-color: var(--color-card-three-g-text) !important;
}

.plan-selection-four-g:checked, .plan-selection-four-g:hover, .plan-selection-four-g:focus, .plan-selection-four-g, .plan-card-hr-four-g {
    border-color: var(--color-card-four-g-text) !important;
}

.plan-selected-box-three-g {
    background-color: var(--color-card-three-g-text) !important;
}

.plan-selected-box-four-g {
    background-color: var(--color-card-four-g-text) !important;
}

.plan-details {
    min-height: 108px;
}

.plan-selection-radio {
    max-width: 110px;
    text-align: end;
}

.successful-validation circle {
    stroke-dasharray: 392;
    stroke-dashoffset: 424;
    animation: green-circle-border 1.5s;
}

.successful-validation rect{
    animation: slide-over 1.5s linear;
    transform-origin: bottom right;
    fill: white !important;
}

@keyframes green-circle-border {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes slide-over {
    to {
        transform: translateY(0);
    }
}