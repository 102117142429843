.theme-public-mobile {
  --color-background: #F4E9D9;
  --color-card-background: #fbf5eb;
  --color-primary: #034045;
  --color-secondary: #B6EBDB;
  --color-tertiary: #000;
  --color-selected-text: #F4E8D9;
  --color-selected-background: #034045;
  --color-unselected-text: #034045;
  --color-unselected-background: #DBD7C9;
  --color-hover-text: #034045;
  --color-hover-background: #B6EBDB;
  --color-copy: #034045;
  --color-copy-inverted: #F4E9D9;
  --color-danger: #EB5757;
  --color-disabled: #756d74;
  --color-cutout: #fbf5eb;
  --color-header: #034045;

  --color-h1: var(--color-copy);
  --color-h2: var(--color-copy);
  --color-h3: var(--color-copy);
  --color-h4: var(--color-copy);
  --color-placeholder:#81a0a2;
  --color-error: #EB5757;
  --color-cut1: #F4E9D9;
  --color-cut2: #F4E9D9;
  --color-popup: #FFF8E9;
  --color-backdrop: rgba(3, 64, 69, 0.25);

  --border-radius: 8px;
  --border-radius-sm: 3px;
  --border-radius-lg: 15px;
  --border-radius-md-lg: 12px;

  --font-family: ['Arial', 'sans-serif'];

  --h1: 'Arial';
  --h2: 'Arial';
  --h3: 'Arial';
  --h4: 'Arial';
  --body: 'Arial';
  --bodySmall: 'Arial';
  --bodyLarge: 'Arial';
  --placeholder: 'Arial';
}
