@font-face {
  font-family: StagSans-Bold;
  src: url('../apps/assets/fonts/koodo/StagSans-Bold-Web.woff') format('woff');
}

@font-face {
  font-family: StagSans-Semibold;
  src: url('../apps/assets/fonts/koodo/StagSans-Semibold-Web.woff') format('woff');
}

@font-face {
  font-family: StagSans-Medium;
  src: url('../apps/assets/fonts/koodo/StagSans-Medium-Web.woff') format('woff');
}

@font-face {
  font-family: StagSans-Book;
  src: url('../apps/assets/fonts/koodo/StagSans-Book-Web.woff') format('woff');
}

.theme-koodo-prepaid {
  --kpre-color-custard-40: rgba(254, 238, 199, 0.4);
  --kpre-color-grape: #7C1366;
  --kpre-color-grape-80: rgba(124, 19, 102, 0.8);
  --kpre-color-kale: #258B0B;
  --kpre-color-lime: #D5E04D;
  --kpre-color-grape-30: rgba(124, 19, 102, 0.3);
  --kpre-color-grape-15: rgba(124, 19, 102, 0.15);
  --kpre-color-texas-yellow: #F9F6A5;
  --kpre-color-green-cyan: #C1F2E8;
  --kpre-color-code:#FEEEC7;
  --kpre-color-blue-cyan: #E5F7FB;

  --color-background: var(--kpre-color-custard-40);
  --color-card-background: white;
  --color-primary: var(--kpre-color-grape);
  --color-code: var(--kpre-color-code);
  --color-primary-80: var(--kpre-color-grape-80);
  --color-secondary: var(--kpre-color-kale);
  --color-tertiary: var(--kpre-color-lime);
  --color-selected-text: white;
  --color-selected-background: var(--kpre-color-grape);
  --color-unselected-text: #2A2C2E;
  --color-unselected-background: var(--kpre-color-grape-15);
  --color-hover-text: #2A2C2E;
  --color-hover-background: var(--kpre-color-grape-30);
  --color-copy: black;
  --color-disabled: #6D7175;
  --color-copy-inverted: white;
  --color-cutout: var(--kpre-color-lime);
  --color-h1: var(--kpre-color-grape);
  --color-h2: var(--kpre-color-grape);
  --color-h3: black;
  --color-h4: black;
  --color-placeholder: #71757b;
  --color-error: #c12335;
  --color-cut1: #17367d;
  --color-cut2: #00addb;
  --color-backdrop: rgba(3, 64, 69, 0.25);
  --color-popup: #FFF8E9;
  --color-header: white;
  --color-yellow: #FBAF34;
  --color-green: #016B6A;
  --color-silver: #C9C8C8;
  --color-alert: #B83228;
  --color-alert-background: #F8DDE3;


  --color-card-three-g: var(--kpre-color-grape);
  --color-card-four-g: var(--kpre-color-texas-yellow);
  --color-card-three-g-text: white;
  --color-card-four-g-text: var(--kpre-color-grape);
  --color-card-three-g-promo: var(--kpre-color-green-cyan);
  --color-card-four-g-promo: var(--kpre-color-grape);
  --color-card-three-g-promo-text: var(--kpre-color-grape);
  --color-card-four-g-promo-text: var(--kpre-color-texas-yellow);
  --color-card-30-days-tag: var(--color-green);
  --color-card-360-days-tag: var(--color-yellow);
  --color-card-360-days-tag-text: #7C1366;
  
  --border-radius: 3px;
  --border-radius-sm: 3px;
  --border-radius-md: 8px;

  --font-family: ['StagSans-Bold', 'Arial', 'sans-serif'];

  --h1: 'StagSans-Bold';
  --h2: 'StagSans-Bold';
  --h3: 'StagSans-Bold';
  --h4: 'StagSans-Semibold';
  --body: 'StagSans-Book';
  --bodySmall: 'StagSans-Semibold';
  --bodyLarge: 'StagSans-Medium';
  --placeholder: 'StagSans-Book';
}
